// prettier-ignore
const countries = [
  { name: 'United States', code: 'US', metadata: { calendlyAllowed:true , calendlyRegion: 'US' } },
  { name: 'India', code: 'IN', metadata: { calendlyAllowed:true , calendlyRegion: 'India' } },
  { name: 'United Kingdom', code: 'GB', metadata: { calendlyAllowed:false , calendlyRegion: 'US' } },
  { name: 'Afghanistan', code: 'AF', metadata: { calendlyAllowed:false , calendlyRegion: 'India' } },
  { name: 'Åland Islands', code: 'AX', metadata: { calendlyAllowed:false , calendlyRegion: 'US' } },
  { name: 'Albania', code: 'AL', metadata: { calendlyAllowed:false , calendlyRegion: 'US' } },
  { name: 'Algeria', code: 'DZ', metadata: { calendlyAllowed:false , calendlyRegion: 'US' } },
  { name: 'American Samoa', code: 'AS', metadata: { calendlyAllowed:false , calendlyRegion: 'US' } },
  { name: 'AndorrA', code: 'AD', metadata: { calendlyAllowed:false , calendlyRegion: 'US' } },
  { name: 'Angola', code: 'AO', metadata: { calendlyAllowed:false , calendlyRegion: 'India' } },
  { name: 'Anguilla', code: 'AI', metadata: { calendlyAllowed:false , calendlyRegion: 'US' } },
  { name: 'Antarctica', code: 'AQ', metadata: { calendlyAllowed:false , calendlyRegion: 'US' } },
  { name: 'Antigua and Barbuda', code: 'AG', metadata: { calendlyAllowed:false , calendlyRegion: 'US' } },
  { name: 'Argentina', code: 'AR', metadata: { calendlyAllowed:false , calendlyRegion: 'US' } },
  { name: 'Armenia', code: 'AM', metadata: { calendlyAllowed:false , calendlyRegion: 'US' } },
  { name: 'Aruba', code: 'AW', metadata: { calendlyAllowed:false , calendlyRegion: 'US' } },
  { name: 'Australia', code: 'AU', metadata: { calendlyAllowed:false , calendlyRegion: 'India' } },
  { name: 'Austria', code: 'AT', metadata: { calendlyAllowed:false , calendlyRegion: 'US' } },
  { name: 'Azerbaijan', code: 'AZ', metadata: { calendlyAllowed:false , calendlyRegion: 'India' } },
  { name: 'Bahamas', code: 'BS', metadata: { calendlyAllowed:false , calendlyRegion: 'US' } },
  { name: 'Bahrain', code: 'BH', metadata: { calendlyAllowed:false , calendlyRegion: 'India' } },
  { name: 'Bangladesh', code: 'BD', metadata: { calendlyAllowed:false , calendlyRegion: 'India' } },
  { name: 'Barbados', code: 'BB', metadata: { calendlyAllowed:false , calendlyRegion: 'US' } },
  { name: 'Belarus', code: 'BY', metadata: { calendlyAllowed:false , calendlyRegion: 'US' } },
  { name: 'Belgium', code: 'BE', metadata: { calendlyAllowed:false , calendlyRegion: 'US' } },
  { name: 'Belize', code: 'BZ', metadata: { calendlyAllowed:false , calendlyRegion: 'US' } },
  { name: 'Benin', code: 'BJ', metadata: { calendlyAllowed:false , calendlyRegion: 'India' } },
  { name: 'Bermuda', code: 'BM', metadata: { calendlyAllowed:false , calendlyRegion: 'US' } },
  { name: 'Bhutan', code: 'BT', metadata: { calendlyAllowed:false , calendlyRegion: 'India' } },
  { name: 'Bolivia', code: 'BO', metadata: { calendlyAllowed:false , calendlyRegion: 'US' } },
  { name: 'Bosnia and Herzegovina', code: 'BA', metadata: { calendlyAllowed:false , calendlyRegion: 'US' } },
  { name: 'Botswana', code: 'BW', metadata: { calendlyAllowed:false , calendlyRegion: 'India' } },
  { name: 'Bouvet Island', code: 'BV', metadata: { calendlyAllowed:false , calendlyRegion: 'US' } },
  { name: 'Brazil', code: 'BR', metadata: { calendlyAllowed:false , calendlyRegion: 'US' } },
  { name: 'British Indian Ocean Territory', code: 'IO', metadata: { calendlyAllowed:false , calendlyRegion: 'India' } },
  { name: 'Brunei Darussalam', code: 'BN', metadata: { calendlyAllowed:false , calendlyRegion: 'India' } },
  { name: 'Bulgaria', code: 'BG', metadata: { calendlyAllowed:false , calendlyRegion: 'US' } },
  { name: 'Burkina Faso', code: 'BF', metadata: { calendlyAllowed:false , calendlyRegion: 'India' } },
  { name: 'Burundi', code: 'BI', metadata: { calendlyAllowed:false , calendlyRegion: 'India' } },
  { name: 'Cambodia', code: 'KH', metadata: { calendlyAllowed:false , calendlyRegion: 'India' } },
  { name: 'Cameroon', code: 'CM', metadata: { calendlyAllowed:false , calendlyRegion: 'India' } },
  { name: 'Canada', code: 'CA', metadata: { calendlyAllowed:true , calendlyRegion: 'US' } },
  { name: 'Cape Verde', code: 'CV', metadata: { calendlyAllowed:false , calendlyRegion: 'US' } },
  { name: 'Cayman Islands', code: 'KY', metadata: { calendlyAllowed:false , calendlyRegion: 'India' } },
  { name: 'Central African Republic', code: 'CF', metadata: { calendlyAllowed:false , calendlyRegion: 'India' } },
  { name: 'Chad', code: 'TD', metadata: { calendlyAllowed:false , calendlyRegion: 'India' } },
  { name: 'Chile', code: 'CL', metadata: { calendlyAllowed:false , calendlyRegion: 'US' } },
  { name: 'China', code: 'CN', metadata: { calendlyAllowed:false , calendlyRegion: 'India' } },
  { name: 'Christmas Island', code: 'CX', metadata: { calendlyAllowed:false , calendlyRegion: 'US' } },
  { name: 'Cocos (Keeling) Islands', code: 'CC', metadata: { calendlyAllowed:false , calendlyRegion: 'US' } },
  { name: 'Colombia', code: 'CO', metadata: { calendlyAllowed:false , calendlyRegion: 'US' } },
  { name: 'Comoros', code: 'KM', metadata: { calendlyAllowed:false , calendlyRegion: 'US' } },
  { name: 'Congo', code: 'CG', metadata: { calendlyAllowed:false , calendlyRegion: 'India' } },
  { name: 'Congo, The Democratic Republic of the', code: 'CD', metadata: { calendlyAllowed:false , calendlyRegion: 'India' } },
  { name: 'Cook Islands', code: 'CK', metadata: { calendlyAllowed:false , calendlyRegion: 'US' } },
  { name: 'Costa Rica', code: 'CR', metadata: { calendlyAllowed:false , calendlyRegion: 'US' } },
  { name: "Cote D'Ivoire", code: 'CI', metadata: { calendlyAllowed:false , calendlyRegion: 'India' } },
  { name: 'Croatia', code: 'HR', metadata: { calendlyAllowed:false , calendlyRegion: 'US' } },
  { name: 'Cuba', code: 'CU', metadata: { calendlyAllowed:false , calendlyRegion: 'US' } },
  { name: 'Cyprus', code: 'CY', metadata: { calendlyAllowed:false , calendlyRegion: 'US' } },
  { name: 'Czech Republic', code: 'CZ', metadata: { calendlyAllowed:false , calendlyRegion: 'US' } },
  { name: 'Denmark', code: 'DK', metadata: { calendlyAllowed:false , calendlyRegion: 'US' } },
  { name: 'Djibouti', code: 'DJ', metadata: { calendlyAllowed:false , calendlyRegion: 'India' } },
  { name: 'Dominica', code: 'DM', metadata: { calendlyAllowed:false , calendlyRegion: 'US' } },
  { name: 'Dominican Republic', code: 'DO', metadata: { calendlyAllowed:false , calendlyRegion: 'US' } },
  { name: 'Ecuador', code: 'EC', metadata: { calendlyAllowed:false , calendlyRegion: 'US' } },
  { name: 'Egypt', code: 'EG', metadata: { calendlyAllowed:false , calendlyRegion: 'India' } },
  { name: 'El Salvador', code: 'SV', metadata: { calendlyAllowed:false , calendlyRegion: 'US' } },
  { name: 'Equatorial Guinea', code: 'GQ', metadata: { calendlyAllowed:false , calendlyRegion: 'India' } },
  { name: 'Eritrea', code: 'ER', metadata: { calendlyAllowed:false , calendlyRegion: 'India' } },
  { name: 'Estonia', code: 'EE', metadata: { calendlyAllowed:false , calendlyRegion: 'US' } },
  { name: 'Ethiopia', code: 'ET', metadata: { calendlyAllowed:false , calendlyRegion: 'India' } },
  { name: 'Falkland Islands (Malvinas)', code: 'FK', metadata: { calendlyAllowed:false , calendlyRegion: 'US' } },
  { name: 'Faroe Islands', code: 'FO', metadata: { calendlyAllowed:false , calendlyRegion: 'US' } },
  { name: 'Fiji', code: 'FJ', metadata: { calendlyAllowed:false , calendlyRegion: 'India' } },
  { name: 'Finland', code: 'FI', metadata: { calendlyAllowed:false , calendlyRegion: 'US' } },
  { name: 'France', code: 'FR', metadata: { calendlyAllowed:true , calendlyRegion: 'US' } },
  { name: 'French Guiana', code: 'GF', metadata: { calendlyAllowed:false , calendlyRegion: 'India' } },
  { name: 'French Polynesia', code: 'PF', metadata: { calendlyAllowed:false , calendlyRegion: 'India' } },
  { name: 'French Southern Territories', code: 'TF', metadata: { calendlyAllowed:false , calendlyRegion: 'India' } },
  { name: 'Gabon', code: 'GA', metadata: { calendlyAllowed:false , calendlyRegion: 'India' } },
  { name: 'Gambia', code: 'GM', metadata: { calendlyAllowed:false , calendlyRegion: 'India' } },
  { name: 'Georgia', code: 'GE', metadata: { calendlyAllowed:false , calendlyRegion: 'US' } },
  { name: 'Germany', code: 'DE', metadata: { calendlyAllowed:true , calendlyRegion: 'US' } },
  { name: 'Ghana', code: 'GH', metadata: { calendlyAllowed:false , calendlyRegion: 'India' } },
  { name: 'Gibraltar', code: 'GI', metadata: { calendlyAllowed:false , calendlyRegion: 'US' } },
  { name: 'Greece', code: 'GR', metadata: { calendlyAllowed:false , calendlyRegion: 'US' } },
  { name: 'Greenland', code: 'GL', metadata: { calendlyAllowed:false , calendlyRegion: 'US' } },
  { name: 'Grenada', code: 'GD', metadata: { calendlyAllowed:false , calendlyRegion: 'US' } },
  { name: 'Guadeloupe', code: 'GP', metadata: { calendlyAllowed:false , calendlyRegion: 'US' } },
  { name: 'Guam', code: 'GU', metadata: { calendlyAllowed:false , calendlyRegion: 'US' } },
  { name: 'Guatemala', code: 'GT', metadata: { calendlyAllowed:false , calendlyRegion: 'US' } },
  { name: 'Guernsey', code: 'GG', metadata: { calendlyAllowed:false , calendlyRegion: 'US' } },
  { name: 'Guinea', code: 'GN', metadata: { calendlyAllowed:false , calendlyRegion: 'India' } },
  { name: 'Guinea-Bissau', code: 'GW', metadata: { calendlyAllowed:false , calendlyRegion: 'India' } },
  { name: 'Guyana', code: 'GY', metadata: { calendlyAllowed:false , calendlyRegion: 'US' } },
  { name: 'Haiti', code: 'HT', metadata: { calendlyAllowed:false , calendlyRegion: 'US' } },
  { name: 'Heard Island and Mcdonald Islands', code: 'HM', metadata: { calendlyAllowed:false , calendlyRegion: 'US' } },
  { name: 'Holy See (Vatican City State)', code: 'VA', metadata: { calendlyAllowed:false , calendlyRegion: 'US' } },
  { name: 'Honduras', code: 'HN', metadata: { calendlyAllowed:false , calendlyRegion: 'US' } },
  { name: 'Hong Kong', code: 'HK', metadata: { calendlyAllowed:false , calendlyRegion: 'India' } },
  { name: 'Hungary', code: 'HU', metadata: { calendlyAllowed:false , calendlyRegion: 'US' } },
  { name: 'Iceland', code: 'IS', metadata: { calendlyAllowed:false , calendlyRegion: 'US' } },
  { name: 'Indonesia', code: 'ID', metadata: { calendlyAllowed:false , calendlyRegion: 'India' } },
  { name: 'Iran, Islamic Republic Of', code: 'IR', metadata: { calendlyAllowed:false , calendlyRegion: 'India' } },
  { name: 'Iraq', code: 'IQ', metadata: { calendlyAllowed:false , calendlyRegion: 'India' } },
  { name: 'Ireland', code: 'IE', metadata: { calendlyAllowed:false , calendlyRegion: 'US' } },
  { name: 'Isle of Man', code: 'IM', metadata: { calendlyAllowed:false , calendlyRegion: 'US' } },
  { name: 'Israel', code: 'IL', metadata: { calendlyAllowed:false , calendlyRegion: 'US' } },
  { name: 'Italy', code: 'IT', metadata: { calendlyAllowed:true , calendlyRegion: 'US' } },
  { name: 'Jamaica', code: 'JM', metadata: { calendlyAllowed:false , calendlyRegion: 'US' } },
  { name: 'Japan', code: 'JP', metadata: { calendlyAllowed:false , calendlyRegion: 'India' } },
  { name: 'Jersey', code: 'JE', metadata: { calendlyAllowed:false , calendlyRegion: 'US' } },
  { name: 'Jordan', code: 'JO', metadata: { calendlyAllowed:false , calendlyRegion: 'India' } },
  { name: 'Kazakhstan', code: 'KZ', metadata: { calendlyAllowed:false , calendlyRegion: 'India' } },
  { name: 'Kenya', code: 'KE', metadata: { calendlyAllowed:false , calendlyRegion: 'India' } },
  { name: 'Kiribati', code: 'KI', metadata: { calendlyAllowed:false , calendlyRegion: 'India' } },
  { name: "Korea, Democratic People'S Republic of", code: 'KP', metadata: { calendlyAllowed:false , calendlyRegion: 'India' } },
  { name: 'Korea, Republic of', code: 'KR', metadata: { calendlyAllowed:false , calendlyRegion: 'India' } },
  { name: 'Kuwait', code: 'KW', metadata: { calendlyAllowed:false , calendlyRegion: 'India' } },
  { name: 'Kyrgyzstan', code: 'KG', metadata: { calendlyAllowed:false , calendlyRegion: 'India' } },
  { name: "Lao People'S Democratic Republic", code: 'LA', metadata: { calendlyAllowed:false , calendlyRegion: 'India' } },
  { name: 'Latvia', code: 'LV', metadata: { calendlyAllowed:false , calendlyRegion: 'US' } },
  { name: 'Lebanon', code: 'LB', metadata: { calendlyAllowed:false , calendlyRegion: 'US' } },
  { name: 'Lesotho', code: 'LS', metadata: { calendlyAllowed:false , calendlyRegion: 'India' } },
  { name: 'Liberia', code: 'LR', metadata: { calendlyAllowed:false , calendlyRegion: 'India' } },
  { name: 'Libyan Arab Jamahiriya', code: 'LY', metadata: { calendlyAllowed:false , calendlyRegion: 'India' } },
  { name: 'Liechtenstein', code: 'LI', metadata: { calendlyAllowed:false , calendlyRegion: 'US' } },
  { name: 'Lithuania', code: 'LT', metadata: { calendlyAllowed:false , calendlyRegion: 'US' } },
  { name: 'Luxembourg', code: 'LU', metadata: { calendlyAllowed:false , calendlyRegion: 'US' } },
  { name: 'Macao', code: 'MO', metadata: { calendlyAllowed:false , calendlyRegion: 'India' } },
  { name: 'Macedonia, The Former Yugoslav Republic of', code: 'MK', metadata: { calendlyAllowed:false , calendlyRegion: 'US' } },
  { name: 'Madagascar', code: 'MG', metadata: { calendlyAllowed:false , calendlyRegion: 'India' } },
  { name: 'Malawi', code: 'MW', metadata: { calendlyAllowed:false , calendlyRegion: 'India' } },
  { name: 'Malaysia', code: 'MY', metadata: { calendlyAllowed:false , calendlyRegion: 'India' } },
  { name: 'Maldives', code: 'MV', metadata: { calendlyAllowed:false , calendlyRegion: 'India' } },
  { name: 'Mali', code: 'ML', metadata: { calendlyAllowed:false , calendlyRegion: 'India' } },
  { name: 'Malta', code: 'MT', metadata: { calendlyAllowed:false , calendlyRegion: 'US' } },
  { name: 'Marshall Islands', code: 'MH', metadata: { calendlyAllowed:false , calendlyRegion: 'US' } },
  { name: 'Martinique', code: 'MQ', metadata: { calendlyAllowed:false , calendlyRegion: 'US' } },
  { name: 'Mauritania', code: 'MR', metadata: { calendlyAllowed:false , calendlyRegion: 'India' } },
  { name: 'Mauritius', code: 'MU', metadata: { calendlyAllowed:false , calendlyRegion: 'India' } },
  { name: 'Mayotte', code: 'YT', metadata: { calendlyAllowed:false , calendlyRegion: 'US' } },
  { name: 'Mexico', code: 'MX', metadata: { calendlyAllowed:false , calendlyRegion: 'US' } },
  { name: 'Micronesia, Federated States of', code: 'FM', metadata: { calendlyAllowed:false , calendlyRegion: 'India' } },
  { name: 'Moldova, Republic of', code: 'MD', metadata: { calendlyAllowed:false , calendlyRegion: 'US' } },
  { name: 'Monaco', code: 'MC', metadata: { calendlyAllowed:false , calendlyRegion: 'US' } },
  { name: 'Mongolia', code: 'MN', metadata: { calendlyAllowed:false , calendlyRegion: 'India' } },
  { name: 'Montserrat', code: 'MS', metadata: { calendlyAllowed:false , calendlyRegion: 'US' } },
  { name: 'Morocco', code: 'MA', metadata: { calendlyAllowed:false , calendlyRegion: 'India' } },
  { name: 'Mozambique', code: 'MZ', metadata: { calendlyAllowed:false , calendlyRegion: 'India' } },
  { name: 'Myanmar', code: 'MM', metadata: { calendlyAllowed:false , calendlyRegion: 'India' } },
  { name: 'Namibia', code: 'NA', metadata: { calendlyAllowed:false , calendlyRegion: 'India' } },
  { name: 'Nauru', code: 'NR', metadata: { calendlyAllowed:false , calendlyRegion: 'India' } },
  { name: 'Nepal', code: 'NP', metadata: { calendlyAllowed:false , calendlyRegion: 'India' } },
  { name: 'Netherlands', code: 'NL', metadata: { calendlyAllowed:true , calendlyRegion: 'US' } },
  { name: 'Netherlands Antilles', code: 'AN', metadata: { calendlyAllowed:false , calendlyRegion: 'US' } },
  { name: 'New Caledonia', code: 'NC', metadata: { calendlyAllowed:false , calendlyRegion: 'US' } },
  { name: 'New Zealand', code: 'NZ', metadata: { calendlyAllowed:false , calendlyRegion: 'India' } },
  { name: 'Nicaragua', code: 'NI', metadata: { calendlyAllowed:false , calendlyRegion: 'US' } },
  { name: 'Niger', code: 'NE', metadata: { calendlyAllowed:false , calendlyRegion: 'India' } },
  { name: 'Nigeria', code: 'NG', metadata: { calendlyAllowed:false , calendlyRegion: 'India' } },
  { name: 'Niue', code: 'NU', metadata: { calendlyAllowed:false , calendlyRegion: 'US' } },
  { name: 'Norfolk Island', code: 'NF', metadata: { calendlyAllowed:false , calendlyRegion: 'US' } },
  { name: 'Northern Mariana Islands', code: 'MP', metadata: { calendlyAllowed:false , calendlyRegion: 'US' } },
  { name: 'Norway', code: 'NO', metadata: { calendlyAllowed:false , calendlyRegion: 'US' } },
  { name: 'Oman', code: 'OM', metadata: { calendlyAllowed:false , calendlyRegion: 'India' } },
  { name: 'Pakistan', code: 'PK', metadata: { calendlyAllowed:false , calendlyRegion: 'India' } },
  { name: 'Palau', code: 'PW', metadata: { calendlyAllowed:false , calendlyRegion: 'India' } },
  { name: 'Palestinian Territory, Occupied', code: 'PS', metadata: { calendlyAllowed:false , calendlyRegion: 'US' } },
  { name: 'Panama', code: 'PA', metadata: { calendlyAllowed:false , calendlyRegion: 'US' } },
  { name: 'Papua New Guinea', code: 'PG', metadata: { calendlyAllowed:false , calendlyRegion: 'India' } },
  { name: 'Paraguay', code: 'PY', metadata: { calendlyAllowed:false , calendlyRegion: 'US' } },
  { name: 'Peru', code: 'PE', metadata: { calendlyAllowed:false , calendlyRegion: 'US' } },
  { name: 'Philippines', code: 'PH', metadata: { calendlyAllowed:false , calendlyRegion: 'India' } },
  { name: 'Pitcairn', code: 'PN', metadata: { calendlyAllowed:false , calendlyRegion: 'US' } },
  { name: 'Poland', code: 'PL', metadata: { calendlyAllowed:false , calendlyRegion: 'US' } },
  { name: 'Portugal', code: 'PT', metadata: { calendlyAllowed:false , calendlyRegion: 'US' } },
  { name: 'Puerto Rico', code: 'PR', metadata: { calendlyAllowed:false , calendlyRegion: 'US' } },
  { name: 'Qatar', code: 'QA', metadata: { calendlyAllowed:false , calendlyRegion: 'India' } },
  { name: 'Reunion', code: 'RE', metadata: { calendlyAllowed:false , calendlyRegion: 'India' } },
  { name: 'Romania', code: 'RO', metadata: { calendlyAllowed:false , calendlyRegion: 'US' } },
  { name: 'Russian Federation', code: 'RU', metadata: { calendlyAllowed:false , calendlyRegion: 'India' } },
  { name: 'RWANDA', code: 'RW', metadata: { calendlyAllowed:false , calendlyRegion: 'India' } },
  { name: 'Saint Helena', code: 'SH', metadata: { calendlyAllowed:false , calendlyRegion: 'US' } },
  { name: 'Saint Kitts and Nevis', code: 'KN', metadata: { calendlyAllowed:false , calendlyRegion: 'US' } },
  { name: 'Saint Lucia', code: 'LC', metadata: { calendlyAllowed:false , calendlyRegion: 'US' } },
  { name: 'Saint Pierre and Miquelon', code: 'PM', metadata: { calendlyAllowed:false , calendlyRegion: 'US' } },
  { name: 'Saint Vincent and the Grenadines', code: 'VC', metadata: { calendlyAllowed:false , calendlyRegion: 'US' } },
  { name: 'Samoa', code: 'WS', metadata: { calendlyAllowed:false , calendlyRegion: 'US' } },
  { name: 'San Marino', code: 'SM', metadata: { calendlyAllowed:false , calendlyRegion: 'US' } },
  { name: 'Sao Tome and Principe', code: 'ST', metadata: { calendlyAllowed:false , calendlyRegion: 'US' } },
  { name: 'Saudi Arabia', code: 'SA', metadata: { calendlyAllowed:false , calendlyRegion: 'India' } },
  { name: 'Senegal', code: 'SN', metadata: { calendlyAllowed:false , calendlyRegion: 'India' } },
  { name: 'Serbia and Montenegro', code: 'CS', metadata: { calendlyAllowed:false , calendlyRegion: 'US' } },
  { name: 'Seychelles', code: 'SC', metadata: { calendlyAllowed:false , calendlyRegion: 'India' } },
  { name: 'Sierra Leone', code: 'SL', metadata: { calendlyAllowed:false , calendlyRegion: 'India' } },
  { name: 'Singapore', code: 'SG', metadata: { calendlyAllowed:false , calendlyRegion: 'India' } },
  { name: 'Slovakia', code: 'SK', metadata: { calendlyAllowed:false , calendlyRegion: 'US' } },
  { name: 'Slovenia', code: 'SI', metadata: { calendlyAllowed:false , calendlyRegion: 'US' } },
  { name: 'Solomon Islands', code: 'SB', metadata: { calendlyAllowed:false , calendlyRegion: 'US' } },
  { name: 'Somalia', code: 'SO', metadata: { calendlyAllowed:false , calendlyRegion: 'India' } },
  { name: 'South Africa', code: 'ZA', metadata: { calendlyAllowed:false , calendlyRegion: 'India' } },
  { name: 'South Georgia and the South Sandwich Islands', code: 'GS', metadata: { calendlyAllowed:false , calendlyRegion: 'US' } },
  { name: 'Spain', code: 'ES', metadata: { calendlyAllowed:true , calendlyRegion: 'US' } },
  { name: 'Sri Lanka', code: 'LK', metadata: { calendlyAllowed:false , calendlyRegion: 'India' } },
  { name: 'Sudan', code: 'SD', metadata: { calendlyAllowed:false , calendlyRegion: 'India' } },
  { name: 'Suriname', code: 'SR', metadata: { calendlyAllowed:false , calendlyRegion: 'US' } },
  { name: 'Svalbard and Jan Mayen', code: 'SJ', metadata: { calendlyAllowed:false , calendlyRegion: 'US' } },
  { name: 'Swaziland', code: 'SZ', metadata: { calendlyAllowed:false , calendlyRegion: 'India' } },
  { name: 'Sweden', code: 'SE', metadata: { calendlyAllowed:false , calendlyRegion: 'US' } },
  { name: 'Switzerland', code: 'CH', metadata: { calendlyAllowed:false , calendlyRegion: 'US' } },
  { name: 'Syrian Arab Republic', code: 'SY', metadata: { calendlyAllowed:false , calendlyRegion: 'India' } },
  { name: 'Taiwan, Province of China', code: 'TW', metadata: { calendlyAllowed:false , calendlyRegion: 'India' } },
  { name: 'Tajikistan', code: 'TJ', metadata: { calendlyAllowed:false , calendlyRegion: 'India' } },
  { name: 'Tanzania, United Republic of', code: 'TZ', metadata: { calendlyAllowed:false , calendlyRegion: 'India' } },
  { name: 'Thailand', code: 'TH', metadata: { calendlyAllowed:false , calendlyRegion: 'India' } },
  { name: 'Timor-Leste', code: 'TL', metadata: { calendlyAllowed:false , calendlyRegion: 'US' } },
  { name: 'Togo', code: 'TG', metadata: { calendlyAllowed:false , calendlyRegion: 'India' } },
  { name: 'Tokelau', code: 'TK', metadata: { calendlyAllowed:false , calendlyRegion: 'India' } },
  { name: 'Tonga', code: 'TO', metadata: { calendlyAllowed:false , calendlyRegion: 'India' } },
  { name: 'Trinidad and Tobago', code: 'TT', metadata: { calendlyAllowed:false , calendlyRegion: 'US' } },
  { name: 'Tunisia', code: 'TN', metadata: { calendlyAllowed:false , calendlyRegion: 'India' } },
  { name: 'Turkey', code: 'TR', metadata: { calendlyAllowed:false , calendlyRegion: 'India' } },
  { name: 'Turkmenistan', code: 'TM', metadata: { calendlyAllowed:false , calendlyRegion: 'India' } },
  { name: 'Turks and Caicos Islands', code: 'TC', metadata: { calendlyAllowed:false , calendlyRegion: 'US' } },
  { name: 'Tuvalu', code: 'TV', metadata: { calendlyAllowed:false , calendlyRegion: 'India' } },
  { name: 'Uganda', code: 'UG', metadata: { calendlyAllowed:false , calendlyRegion: 'India' } },
  { name: 'Ukraine', code: 'UA', metadata: { calendlyAllowed:false , calendlyRegion: 'US' } },
  { name: 'United Arab Emirates', code: 'AE', metadata: { calendlyAllowed:false , calendlyRegion: 'India' } },
  { name: 'United States Minor Outlying Islands', code: 'UM', metadata: { calendlyAllowed:false , calendlyRegion: 'US' } },
  { name: 'Uruguay', code: 'UY', metadata: { calendlyAllowed:false , calendlyRegion: 'US' } },
  { name: 'Uzbekistan', code: 'UZ', metadata: { calendlyAllowed:false , calendlyRegion: 'India' } },
  { name: 'Vanuatu', code: 'VU', metadata: { calendlyAllowed:false , calendlyRegion: 'India' } },
  { name: 'Venezuela', code: 'VE', metadata: { calendlyAllowed:false , calendlyRegion: 'US' } },
  { name: 'Viet Nam', code: 'VN', metadata: { calendlyAllowed:false , calendlyRegion: 'India' } },
  { name: 'Virgin Islands, British', code: 'VG', metadata: { calendlyAllowed:false , calendlyRegion: 'US' } },
  { name: 'Virgin Islands, U.S.', code: 'VI', metadata: { calendlyAllowed:false , calendlyRegion: 'US' } },
  { name: 'Wallis and Futuna', code: 'WF', metadata: { calendlyAllowed:false , calendlyRegion: 'US' } },
  { name: 'Western Sahara', code: 'EH', metadata: { calendlyAllowed:false , calendlyRegion: 'India' } },
  { name: 'Yemen', code: 'YE', metadata: { calendlyAllowed:false , calendlyRegion: 'India' } },
  { name: 'Zambia', code: 'ZM', metadata: { calendlyAllowed:false , calendlyRegion: 'India' } },
  { name: 'Zimbabwe', code: 'ZW', metadata: { calendlyAllowed:false , calendlyRegion: 'India' } }
]

export default countries
